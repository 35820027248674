<template>
  <div class="platform-home-page h-full">
    <p-header></p-header>
    <Map />
    <left-pannel></left-pannel>
    <right-pannel></right-pannel>
  </div>
</template>

<script>
import PHeader from "@/components/PHeader";
import Map from "./components/map.vue";
import LeftPannel from "./components/leftPannel.vue";
import RightPannel from "./components/rightPannel.vue";
export default {
  components: {
    PHeader,
    Map,
    LeftPannel,
    RightPannel,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
