<template>
  <div ref="dom" class="charts chart-bar"></div>
</template>

<script>
import * as echarts from "echarts";
import { on, off } from "@/libs/tools";
export default {
  name: "ChartBar",
  props: {
    value: Array,
    text: String,
    subtext: String,
  },
  data() {
    return {
      dom: null,
    };
  },
  watch: {
    value: {
      handler(val) {
        this.loadEcharts(val);
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {},
  methods: {
    resize() {
      this.dom.resize();
    },
    loadEcharts(val) {
      this.$nextTick(() => {
        let colors1 = ["#276FF6", "#EC9137", "#51B1AA", "#DD4139"];
        let colors2 = ["#5185ED", "#F7DA4D", "#62D6D5", "#ED6B67"];
        val.forEach((item, index) => {
          item.itemStyle = {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: colors1[index],
              },
              {
                offset: 1,
                color: colors2[index],
              },
            ]),
          };
        });
        let option = {
          tooltip: {
            trigger: "axis",
          },
          legend: {
            show: false,
            top: "5%",
            right: "5%",
            itemWidth: 12,
            itemHeight: 3,

            color: "#fff",
            fontSize: 12,
          },
          grid: {
            top: "10%",
            left: "15%",
            right: "10%",
            bottom: "20%",
            // containLabel: true
          },
          xAxis: [
            {
              type: "category",
              show: false,
            },
          ],
          yAxis: [
            {
              name: "",
              show: false,
            },
          ],
          series: [
            {
              type: "pie",
              radius: ["40%", "70%"],
              center: ["50%", "50%"],
              startAngle: 180,
              label: {
                show: true,
                formatter: function (param) {
                  let style = "";
                  switch (param.name) {
                    case "Ⅰ类":
                      style = "s1";
                      break;
                    case "Ⅱ类":
                      style = "s2";
                      break;
                    case "Ⅲ类":
                      style = "s3";
                      break;
                    case "Ⅳ类":
                      style = "s4";
                      break;
                  }
                  return (
                    "{a|" +
                    param.name +
                    "}" +
                    "{" +
                    style +
                    "|" +
                    "   " +
                    param.value +
                    "%}"
                  );
                },
                fontSize: 12,
                rich: {
                  a: {
                    color: "#fff",
                    fontSize: "14",
                  },
                  s1: {
                    color: "#5491FF",
                    fontSize: "14",
                  },
                  s2: {
                    color: "#E75C56",
                    fontSize: "14",
                  },
                  s3: {
                    color: "#F2BA43",
                    fontSize: "14",
                  },
                  s4: {
                    color: "#5CCAC7",
                    fontSize: "14",
                  },
                },
                position: "outside",
              },
              labelLine: {
                show: true,
                length: 10,
                length2: 30,
                color: "#4BCFFC",
              },
              data: val,
            },
          ],
        };
        if (this.dom != null && this.dom != "" && this.dom != undefined) {
          this.dom.dispose();
        }
        this.dom = echarts.init(this.$refs.dom);
        this.dom.setOption(option);
        on(window, "resize", this.resize);
      });
    },
  },

  beforeDestroy() {
    off(window, "resize", this.resize);
  },
};
</script>
