<template>
  <div class="p-header flex justify-between absolute items-center">
    <div class="header-title">
      <img src="../../assets/images/platform/logo.png" alt="" />
    </div>
    <div class="header-date">
      <span class="time mr-3">
        <i class="iconfont icon-shijian mr-1"></i>
        <span>{{ datatime | dateFormat }}</span>
      </span>
      <span class="switch">
        <i class="iconfont icon-jiantou_zuoyouqiehuan" title="后台管理" @click.stop="goto"></i>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      datatime: new Date(),
    };
  },
  filters: {
    // 时间格式化
    dateFormat(value) {
      var addZero = function (value) {
        return value > 9 ? value : "0" + value;
      };
      return (
        value.getFullYear() +
        "/" +
        (value.getMonth() + 1) +
        "/" +
        value.getDate() +
        "/" +
        "     " +
        value.getHours() +
        ":" +
        addZero(value.getMinutes()) +
        ":" +
        addZero(value.getSeconds())
      );
    },
  },
  created() {
    this.getInTime();
  },
  methods: {
    // 获取实时时间
    getInTime() {
      let self = this;
      setInterval(function () {
        self.datatime = new Date();
      }, 1000);
    },
    goto(){
      // localStorage.setItem('type','platform')
      this.$router.push('/platform/bridge-group')
    },
  },
};
</script>

<style lang="less" scoped>
.p-header {
  width: 100%;
  z-index: 9;
  height: 74px;
  background: url("../../assets/images/platform/top_bg.png") top center
    no-repeat;
  background-size: 100% 100%;
  .header-title {
    width: 40%;
    padding-left: 15px;
    > img {
      width: 100%;
    }
  }
  .header-date {
    padding-right: 60px;
    color: #55adff;
    .switch {
      display: inline-block;
      width: 28px;
      height: 28px;
      line-height: 28px;
      border: 1px solid #55adff;
      border-radius: 4px;
      text-align: center;
      cursor: pointer;
    }
  }
}
</style>
