<template>
  <div class="p-pannel p-left-pannel">
    <div class="pannel-item-one">
      <div class="pannel-item-title">桥群总览</div>
      <div class="chart-container">
        <chart-one style="height: 100%" :value="chartData01"></chart-one>
      </div>
    </div>
    <div class="pannel-item-two">
      <div class="pannel-item-title">健康度总览</div>
      <div class="chart-container">
        <chart-two style="height: 100%" :value="chartData02"></chart-two>
      </div>
    </div>
    <div class="pannel-item-three">
      <div class="pannel-item-title">桥群列表</div>
      <div class="chart-container" style="padding:10px 10px 10px 20px">
        <ul class="bridge-list div-scroll">
          <li
            class="bridge-item flex items-center"
            v-for="(item, index) in bridgeList"
            :key="index"
          >
            <img src="../../../assets/images/platform/bridge.png" alt="" />
            <div class="info">
              <div class="info-title ellipsis" :title="item.name">
                {{ item.name }}
              </div>
              <div class="info-des ellipsis" :title="item.type">
                {{ item.type }}
              </div>
              <div class="info-des ellipsis" :title="item.value">
                {{ item.value }}
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import ChartOne from "../charts/chart01.vue";
import ChartTwo from "../charts/chart02.vue";
export default {
  components: {
    ChartOne,
    ChartTwo,
  },
  data() {
    return {
      chartData01: [
        { name: "斜拉桥", value: 11 },
        { name: "悬索桥", value: 5 },
        { name: "梁桥", value: 9 },
        { name: "拱桥", value: 6 },
      ],
      chartData02: [
        { name: "Ⅰ类", value: 22 },
        { name: "Ⅱ类", value: 28 },
        { name: "Ⅲ类", value: 30 },
        { name: "Ⅳ类", value: 20 },
      ],
      bridgeList: [
        { name: "丹江口大桥", type: "斜拉桥", value: "267m+1088m+228m" },
        { name: "丹江口大桥", type: "斜拉桥", value: "267m+1088m+228m" },
        { name: "丹江口大桥", type: "斜拉桥", value: "267m+1088m+228m" },
      ],
    };
  },
};
</script>
