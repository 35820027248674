export const heatmapData = [
    {
        "lng": 114.298572,
        "lat": 30.584355,
        "count": 1159
    },
    {
        "lng": 115.077048,
        "lat": 30.220074,
        "count": 980
    },
    {
        "lng": 110.787916,
        "lat": 32.646907,
        "count": 1481
    },
    {
        "lng": 111.290843,
        "lat": 30.702636,
        "count": 92
    },
    {
        "lng": 112.144146,
        "lat": 32.042426,
        "count": 1127
    },
    {
        "lng": 114.890593,
        "lat": 30.396536,
        "count": 1213
    },
    {
        "lng": 112.204251,
        "lat": 31.03542,
        "count": 11
    },
    {
        "lng": 112.204251,
        "lat": 31.03542,
        "count": 12
    },
    {
        "lng": 113.926655,
        "lat": 30.926423,
        "count": 1302
    },
    {
        "lng": 112.23813,
        "lat": 30.326857,
        "count": 542
    },
    {
        "lng": 114.879365,
        "lat": 30.447711,
        "count": 3
    },
    {
        "lng": 114.328963,
        "lat": 29.832798,
        "count": 3
    },
    {
        "lng": 113.37377,
        "lat": 31.717497,
        "count": 6
    },
    {
        "lng": 109.48699,
        "lat": 30.283114,
        "count": 321
    },
    {
        "lng": 113.453974,
        "lat": 30.364953,
        "count": 763
    },
    {
        "lng": 112.896866,
        "lat": 30.421215,
        "count": 16
    },
    {
        "lng": 113.165862,
        "lat": 30.653061,
        "count": 191
    },
    {
        "lng": 110.671525,
        "lat": 31.744449,
        "count": 1183
    },
]