<template>
  <div ref="dom" class="charts chart-bar"></div>
</template>

<script>
import * as echarts from "echarts";
import { on, off } from "@/libs/tools";
export default {
  name: "ChartBar",
  props: {
    value: Array,
    text: String,
    subtext: String,
  },
  data() {
    return {
      dom: null,
    };
  },
  watch: {
    value: {
      handler(val) {
        this.loadEcharts(val);
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {},
  methods: {
    resize() {
      this.dom.resize();
    },
    loadEcharts(val) {
      this.$nextTick(() => {
        let colors = [
          "#00b8ff",
          "#00d6d3",
          "#ffcb00",
          "#3671fe",
          "#f33a39",
          "#bf69e9",
          "#00ea9c",
          "#744eff",
          "#F86464",
          "#19DC7C",
          "#34A6FE",
          "#FA9022",
          "#DCDB01",
          "#8C70F8",
          "#2A4AD1",
          "#E76FE3",
          "#5032C0",
          "#168FB2",
        ];
        let rich = {};
        colors.forEach((item, index) => {
          rich["s" + index] = { color: item, fontWeight: "bold" };
        });
        let option = {
          color: colors,
          // color: ["#F86464", "#19DC7C", "#34A6FE", "#FA9022", "#DCDB01", "#8C70F8", "#2A4AD1", "#E76FE3", "#5032C0", "#168FB2"],
          grid: {
            left: "2%",
            top: "1%",
            bottom: 10,
            right: "1%",
            containLabel: true,
          },
          tooltip: {
            trigger: "item",
            backgroundColor: "rgba(37, 59, 115, 0.7)",
            textStyle: {
              color: "#fff",
            },
            formatter: "{b} : {c}",
          },
          calculable: true,
          series: [
            {
              stack: "a",
              type: "pie",
              radius: ["40%", "70%"],
              center: ["50%", "50%"],
              roseType: "area",
              zlevel: 10,
              label: {
                show: true,
                padding: [0, -90],
                fontSize: 12,
                align: "center",
                height: 30,
                borderWidth: 0,
                borderRadius: 4,
                formatter: function (param) {
                  let style = "s" + param.dataIndex;
                  return (
                    "{a|" +
                    param.name +
                    "}" +
                    "{" +
                    style +
                    "|" +
                    "   " +
                    param.value +
                    "}"
                  );
                },

                rich: {
                  a: {
                    color: "#fff",
                    fontSize: "12",
                  },
                  ...rich,
                },
                position: "outside",
              },
              labelLine: {
                show: true,
                length: 10,
                length2: 90,
              },
              data: val,
            },
          ],
        };
        if (this.dom != null && this.dom != "" && this.dom != undefined) {
          this.dom.dispose();
        }
        this.dom = echarts.init(this.$refs.dom);
        this.dom.setOption(option);
        on(window, "resize", this.resize);
      });
    },
  },

  beforeDestroy() {
    off(window, "resize", this.resize);
  },
};
</script>
