<template>
  <div ref="dom" class="charts chart-bar"></div>
</template>

<script>
import * as echarts from "echarts";
import { on, off } from "@/libs/tools";
export default {
  name: "ChartBar",
  props: {
    value: Array,
    text: String,
    subtext: String,
  },
  data() {
    return {
      dom: null,
    };
  },
  watch: {
    value: {
      handler(val) {
        this.loadEcharts(val);
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {},
  methods: {
    resize() {
      this.dom.resize();
    },
    loadEcharts(val) {
      this.$nextTick(() => {
        let xAxisData = val.map((item) => item.name);
        let seriesData01 = val.map((item) => item.value);
        let option = {
          tooltip: {
            trigger: "axis",
          },
          legend: {
            show: false,
            top: "5%",
            right: "5%",
            itemWidth: 12,
            itemHeight: 3,

            color: "#fff",
            fontSize: 12,
          },
          grid: {
            top: "25%",
            left: "12%",
            right: "8%",
            bottom: "20%",
            // containLabel: true
          },
          xAxis: [
            {
              type: "category",
              boundaryGap: true,
              axisLine: {
                //坐标轴轴线相关设置。数学上的x轴
                show: false,
                lineStyle: {
                  color: "#233653",
                },
              },
              axisLabel: {
                //坐标轴刻度标签的相关设置

                color: "rgba(204, 227, 255, 0.5)",
                padding: 4,
                fontSize: 12,

                formatter: function (data) {
                  return data;
                },
              },
              splitLine: {
                show: false,
                lineStyle: {
                  type: "dashed",
                  color: "rgba(76,198,255,0.25)",
                },
              },
              axisTick: {
                show: false,
              },
              data: xAxisData,
            },
          ],
          yAxis: [
            {
              name: "",
              nameTextStyle: {
                color: "#3CB6FC",
                fontSize: 12,
                padding: 10,
                align: "right",
              },
              min: 0,
              splitLine: {
                show: true,
                lineStyle: {
                  type: "dashed",
                  color: "rgba(76, 198, 255, 0.25)",
                },
              },
              axisLine: {
                show: false,
                lineStyle: {
                  color: "#233653",
                },
              },
              axisLabel: {
                show: true,

                color: "rgba(222, 248, 255, 0.5)",
                padding: 10,

                formatter: function (value) {
                  if (value === 0) {
                    return value;
                  }
                  return value;
                },
              },
              axisTick: {
                show: false,
              },
            },
          ],
          series: [
            {
              name: "监测值",
              type: "bar",
              itemStyle: {
                borderRadius: 0,
                color: "#00b9ff",
              },
              barWidth: "20%",
              label: {
                show: false,
                color: "#74A2FF",
                position: "outside",
              },
              data: seriesData01,
            },
          ],
        };
        if (this.dom != null && this.dom != "" && this.dom != undefined) {
          this.dom.dispose();
        }
        this.dom = echarts.init(this.$refs.dom);
        this.dom.setOption(option);
        on(window, "resize", this.resize);
      });
    },
  },

  beforeDestroy() {
    off(window, "resize", this.resize);
  },
};
</script>
