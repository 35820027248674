<template>
  <div class="map-container w-full h-full relative" id="container">
    <div class="top-left flex items-center">
      <div
        class="top-left-item mr-3"
        :class="{ active: current == 1 }"
        @click.stop="handleMenuClick(1)"
      >
        <i class="iconfont icon-fengxiang mr-1"></i>
        <span>风环境监测</span>
      </div>
      <div
        class="top-left-item"
        :class="{ active: current == 2 }"
        @click.stop="handleMenuClick(2)"
      >
        <i class="iconfont icon-wenshidu mr-1"></i>
        <span>温湿度监测</span>
      </div>
    </div>
    <div class="top-right flex items-center">
      <div
        class="top-right-item"
        @click.stop="
          showLineOption = true;
          showAreaOption = false;
        "
        v-click-outside.stop="onClickOutside"
      >
        <span class="mr-3">{{ lineName | formatName }}</span>
        <i class="iconfont icon-arrowdownb"></i>
        <ul class="div-scroll item-dropdown" v-show="showLineOption">
          <li
            v-for="(item, index) in lineOptions"
            :key="index"
            class="ellipsis"
            @click.stop="handleLineChange(item)"
          >
            {{ item.name }}
          </li>
        </ul>
      </div>
      <div
        class="top-right-item"
        @click.stop="
          showLineOption = false;
          showAreaOption = true;
        "
        v-click-outside.stop="onClickOutside"
      >
        <span class="mr-3">{{ areaName | formatName }}</span>
        <i class="iconfont icon-arrowdownb"></i>
        <ul class="div-scroll item-dropdown" v-show="showAreaOption">
          <li
            v-for="(item, index) in pointArr"
            :key="index"
            class="ellipsis"
            @click.stop="handleAreaChange(item)"
          >
            {{ item.name }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { heatmapData } from "../../../assets/json/heatmapData";
export default {
  data() {
    return {
      current: "1",
      colors: {
        420100: "#066bd4", // 武汉
        420200: "#77c6f9", // 黄石
        420300: "#0244c7", // 十堰
        420500: "#77c6f9", // 宜昌
        420600: "#066bd4", // 襄阳
        420700: "#4ca5ff", // 鄂州
        420800: "#4ca5ff", // 荆门
        420900: "#77c6f9", // 孝感
        421000: "#0244c7", // 荆州
        421100: "#0244c7", // 黄冈
        421200: "#4ca5ff", // 咸宁
        421300: "#0244c7", // 随州
        422800: "#066bd4", // 恩施
        429004: "#53a3ff", // 仙桃
        429005: "#77c6f9", // 潜江
        429006: "#066bd4", // 天门
        429021: "#4ca5ff", // 神农架
      },
      pointArr: [
        {
          adcode: 420100,
          name: "武汉市",
          //   center: [114.298572, 30.584355],
          center: [114.348204, 30.623025],
        },
        {
          adcode: 420200,
          name: "黄石市",
          //   center: [115.077048, 30.220074],
          center: [115.02984, 29.925181],
        },
        {
          adcode: 420300,
          name: "十堰市",
          //   center: [110.787916, 32.646907],
          center: [110.446495, 32.45798],
        },
        {
          adcode: 420500,
          name: "宜昌市",
          //   center: [111.290843, 30.702636],
          center: [111.140801, 30.747312],
        },
        {
          adcode: 420600,
          name: "襄阳市",
          //   center: [112.144146, 32.042426],
          center: [111.944787, 31.930988],
        },
        {
          adcode: 420700,
          name: "鄂州市",
          //   center: [114.890593, 30.396536],
          center: [114.759738, 30.326618],
        },
        {
          adcode: 420800,
          name: "荆门市",
          //   center: [112.204251, 31.03542],
          center: [112.614753, 31.06948],
        },
        {
          adcode: 420900,
          name: "孝感市",
          //   center: [113.926655, 30.926423],
          center: [113.885608, 31.118116],
        },
        {
          adcode: 421000,
          name: "荆州市",
          //   center: [112.23813, 30.326857],
          center: [112.574526, 29.995051],
        },
        {
          adcode: 421100,
          name: "黄冈市",
          //   center: [114.879365, 30.447711],
          center: [115.343867, 30.717343],
        },
        {
          adcode: 421200,
          name: "咸宁市",
          //   center: [114.328963, 29.832798],
          center: [114.180828, 29.622169],
        },
        {
          adcode: 421300,
          name: "随州市",
          //   center: [113.37377, 31.717497],
          center: [113.429995, 31.828026],
        },
        {
          adcode: 422800,
          name: "恩施土家族苗族自治州",
          //   center: [109.48699, 30.283114],
          center: [109.555117, 30.191808],
        },
        {
          adcode: 429004,
          name: "仙桃市",
          //   center: [113.453974, 30.364953],
          center: [113.381345, 30.287564],
        },
        {
          adcode: 429005,
          name: "潜江市",
          //   center: [112.896866, 30.421215],
          center: [112.762642, 30.336071],
        },
        {
          adcode: 429006,
          name: "天门市",
          //   center: [113.165862, 30.653061],
          center: [113.114735, 30.642494],
        },
        {
          adcode: 429021,
          name: "神农架林区",
          //   center: [110.671525, 31.744449],
          center: [110.480761, 31.589684],
        },
      ],

      lineOptions: [
        { name: "线路一" },
        { name: "线路二" },
        { name: "线路三" },
        { name: "线路四" },
        { name: "线路五" },
      ],
      showLineOption: false,
      showAreaOption: false,
      lineName: "线路",
      areaName: "区域",
      bridgeList: [
        { id:1,name: "开封峪大桥", center: [109.919346, 30.502095], angle: 10,baseUrl:'kfy' },
        { id:2,name: "丹江口大桥", center: [110.489837, 30.934909], angle: 20,baseUrl:'djk' },
        { id:3,name: "清溪河大桥", center: [111.083335, 30.653628], angle: 30,baseUrl:'qxh' },
        { id:4,name: "丹江口汉江大桥", center: [112.00428, 31.053326], angle: 40,baseUrl:'djkhj'},
        { id:5,name: "随岳汉江大桥", center: [112.628476, 30.684433], angle: 130 },
        { id:6,name: "仙桃汉江大桥", center: [113.017319, 30.353869], angle: 90 },
      ],
      markers: [],
      polyLines: [],
      heatmapLayer: null,
    };
  },
  filters: {
    formatName(value) {
      if (value.length < 4) {
        return value;
      } else {
        return value.slice(0, 2) + "...";
      }
    },
  },
  created() {
    localStorage.setItem('baseUrl','api')
    this.map = null;
    this.DistrictLayer = null;
    this.heatmap = null;
    this.bridgeLableLayer = null;
  },
  mounted() {
    this.initMap();
    this.handleMenuClick(1);
  },
  methods: {
    onClickOutside() {
      this.showLineOption = false;
      this.showAreaOption = false;
    },
    handleMenuClick(current) {
      this.current = current;
      if (this.markers.length) this.map.remove(this.markers);
      if (this.polyLines.length) this.map.remove(this.polyLines);
      if (this.heatmap) this.heatmap.setMap(null);
      if (current == 1) {
        this.markers = [];
        this.polyLines = [];
        let len = this.bridgeList.length;
        // 叠加文字图层
        if (this.bridgeLableLayer) {
          this.bridgeLableLayer.show();
        } else {
          this.drawLabel(this.bridgeList, 1, 3, (layer) => {
            this.bridgeLableLayer = layer;
          });
        }
        this.bridgeList.forEach((item, index) => {
          // 叠加桥梁marker
          this.drawMarker(item.center, "icon_fengxiang", item.angle, item);
          // 叠加线路
          if (index < len - 1) {
            this.drawPolyline([item.center, this.bridgeList[index + 1].center]);
          }
        });
      } else {
        if (this.bridgeLableLayer) {
          this.bridgeLableLayer.hide();
        }
        // 设置热力图数据
        this.setHeatmapData(heatmapData);
      }
    },
    handleLineChange(data) {
      this.showLineOption = false;
      this.lineName = data.name;
    },
    handleAreaChange(data) {
      this.showAreaOption = false;
      this.areaName = data.name;
    },
    initMap() {
      let self = this;
      var map = new AMap.Map("container", {
        zoom: 7.3,
        center: [112.199009, 31.035445],
        viewMode: "3D",
        pitch: 30,
        // dragEnable: false,
        features: ['bg','road','building'],
        // mapStyle: "amap://styles/1f3d55491849728d713935044c4a772e",
        mapStyle: "amap://styles/bcf1c3935f2a45a6a0f6626e07befd21",
        zooms: [3, 12],
      });
      this.map = map;
      let bounds = map.getBounds();
      this.imageLayer = new AMap.ImageLayer({
        url: require("../../../assets/images/platform/bg.png"),
        bounds,
        //   bounds: new AMap.Bounds(
        //     [180, 90],
        //     [-180, -90]
        // ),
        zooms: [3, 18],
      });
      // map.addLayer(this.imageLayer);
      // map.on("zoomchange", (e) => {
      //   let bounds = map.getBounds();
      //   self.imageLayer.setBounds(bounds);
      // });
      // map.on("mousemove", (e) => {
      //   let bounds = map.getBounds();
      //   self.imageLayer.setBounds(bounds);
      // });

      var geojson = new AMap.GeoJSON({
        geoJSON: require("../../../assets/json/420000.json"),
        // 还可以自定义getMarker和getPolyline
        getPolygon: function (geojson, lnglats) {
          return new AMap.Polygon({
            path: lnglats,
            fillOpacity: 0.8, // 面积越大透明度越高
            // strokeColor: "#54ddff",
            // strokeWidth: 0.5,
            fillColor: self.colors[geojson.properties.adcode],
          });
        },
        getMarker: function (geojson, lnglats) {
          console.log("点", lnglats);
        },
        getPolyline: function (geojson, lnglats) {
          console.log("线", lnglats);
        },
      });
      map.add(geojson);
      this.drawLabel(this.pointArr, 0.5, 2);
      // 叠加热力图
      map.plugin(["AMap.HeatMap"], function () {
        //初始化heatmap对象
        self.heatmap = new AMap.HeatMap(map, {
          radius: 100, //给定半径
          opacity: [0, 0.6],
          /*,
            gradient:{
                0.5: 'blue',
                0.65: 'rgb(117,211,248)',
                0.7: 'rgb(0, 255, 0)',
                0.9: '#ffea00',
                1.0: 'red'
            }
             */
        });
      });
    },
    setHeatmapData(data) {
      //设置数据集：该数据为北京部分“公园”数据
      this.heatmap.setDataSet({
        data: data,
        // max: 100,
      });
      this.heatmap.setMap(this.map);
    },
    drawLabel(data, opacity, zIndex, fn) {
      var layer = new AMap.LabelsLayer({
        zooms: [3, 20],
        zIndex: 1000,
        collision: false,
        allowCollision: false,
      });
      var markers = [];
      data.forEach((point) => {
        var marker = new AMap.LabelMarker({
          name: point.name,
          position: point.center,
          zIndex,
          opacity,
          text: {
            content: point.name,
            direction: "right",
            style: {
              fillColor: "#fff",
              padding: [3, 3, 3, 8],
            },
          },
        });
        markers.push(marker);
      });

      layer.add(markers);
      fn && fn(layer);
      // 图层添加到地图
      this.map.add(layer);
    },
    drawMarker(position, icon, angle = 0, extData = {}) {
      let self = this;
      // this.markers = [];
      let anchorIcon = new AMap.Icon({
        // 图标尺寸
        size: new AMap.Size(17, 26),
        // 图标的取图地址
        image: require("../../../assets/images/platform/" + icon + ".png"),
        // 图标所用图片大小
        imageSize: new AMap.Size(17, 26),
      });
      var marker = new AMap.Marker({
        position: new AMap.LngLat(position[0], position[1]),
        icon: anchorIcon,
        offset: new AMap.Pixel(0, 0),
        anchor: "center",
        angle,
        map: this.map,
        extData: extData,
      });
      this.markers.push(marker);
      marker.on("click", function (e) {
        if (extData.baseUrl){
          localStorage.setItem('baseUrl',extData.baseUrl)
        }
        self.$router.push("/single");
        // window.open("/#/single", "_blank");
      });
    },
    drawPolyline(path) {
      let self = this;
      // this.polyLines = [];
      let polyLine = new AMap.Polyline({
        map: this.map,
        path,
        showDir: false,
        strokeColor: "#05F8FB", // 线颜色
        strokeWeight: 4, // 线宽
        strokeStyle: "dashed",
      });
      this.polyLines.push(polyLine);
      polyLine.on("click", function (e) {
        let currentLine = e.target;
        self.polyLines.forEach((item) => {
          item.setOptions({
            strokeColor: "#05F8FB", // 线颜色
            strokeWeight: 4, // 线宽
            strokeStyle: "dashed",
          });
        });
        currentLine.setOptions({
          strokeColor: "#f3cb48", // 线颜色
          strokeWeight: 4, // 线宽
          strokeStyle: "solid",
        });
      });
    },
  },
};
</script>
