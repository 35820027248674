<template>
  <div class="p-pannel p-right-pannel">
    <div class="pannel-item-one">
      <div class="pannel-item-title">车流量监测</div>
      <div class="chart-container">
        <chart-three style="height: 100%" :value="chartData01"></chart-three>
      </div>
    </div>
    <div class="pannel-item-two">
      <div class="pannel-item-title">报警监测</div>
      <div class="chart-container">
        <chart-four style="height: 100%" :value="chartData02"></chart-four>
      </div>
    </div>
    <div class="pannel-item-three">
      <div class="pannel-item-title flex justify-between items-center">
        <span>报警信息</span>
        <div
          class="select-box flex items-center"
          @click.stop="showDropdown = true"
          v-click-outside.stop="onClickOutside"
        >
          <span class="mr-2">{{ current }}</span>
          <i class="iconfont icon-arrowdownb"></i>
          <ul class="div-scroll item-dropdown" v-show="showDropdown">
            <li
              v-for="(item, index) in bridgeList"
              :key="index"
              class="ellipsis"
              @click.stop="handleChange(item)"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
      </div>
      <div class="chart-container" style="padding: 6px 0">
        <vue-seamless-scroll
          :data="alertList"
          class="seamless-warp"
          :class-option="classOption"
        >
          <div class="alert-list">
            <el-row
              class="a-list-item"
              v-for="(item, index) in alertList"
              :key="index"
            >
              <el-col :span="6">{{ item.name }}</el-col>
              <el-col :span="5">{{ item.level }}</el-col>
              <el-col :span="4">{{ item.value }}</el-col>
              <el-col :span="5">{{ item.date }}</el-col>
              <el-col :span="4">{{ item.time }}</el-col>
            </el-row>
          </div>
        </vue-seamless-scroll>
      </div>
    </div>
  </div>
</template>

<script>
import vueSeamlessScroll from "vue-seamless-scroll";
import ChartThree from "../charts/chart03.vue";
import ChartFour from "../charts/chart04.vue";
export default {
  components: {
    vueSeamlessScroll,
    ChartThree,
    ChartFour,
  },
  data() {
    return {
      current: "南里渡特大桥",
      showDropdown: false,
      bridgeList: [
        { name: "南里渡特大桥", center: [109.919346, 30.502095], angle: 10 },
        { name: "巴东长江大桥", center: [110.489837, 30.934909], angle: 20 },
        { name: "龙潭河特大桥", center: [111.083335, 30.653628], angle: 30 },
        { name: "沙洋汉江大桥", center: [112.00428, 31.053326], angle: 40 },
        { name: "随岳汉江大桥", center: [112.628476, 30.684433], angle: 130 },
        { name: "仙桃汉江大桥", center: [113.017319, 30.353869], angle: 90 },
      ],
      chartData01: [
        { name: "丹江口大桥", value: "3100" },
        { name: "丹江口大桥", value: "3300" },
        { name: "丹江口大桥", value: "2500" },
        { name: "丹江口大桥", value: "2748" },
        { name: "丹江口大桥", value: "2300" },
        { name: "丹江口大桥", value: "1900" },
        { name: "丹江口大桥", value: "2000" },
        { name: "丹江口大桥", value: "2400" },
        { name: "丹江口大桥", value: "2500" },
      ],
      chartData02: [
        { name: "随岳江汉大桥", value: "10" },
        { name: "南里渡特大桥", value: "10" },
        { name: "丹江口大桥", value: "4" },
        { name: "巴东长江大桥", value: "4" },
        { name: "仙桃汉江大桥", value: "15" },
        { name: "丹江口大桥1", value: "9" },
        { name: "丹江口大桥2", value: "6" },
        { name: "丹江口大桥3", value: "10" },
        { name: "丹江口大桥4", value: "12" },
      ],
      alertList: [
        {
          name: "上游DEF01挠度",
          level: "黄色预警",
          value: 7.95,
          date: "2022-4-10",
          time: "10:00",
        },
        {
          name: "上游DEF01挠度",
          level: "黄色预警",
          value: 7.95,
          date: "2022-4-10",
          time: "10:00",
        },
        {
          name: "上游DEF01挠度",
          level: "黄色预警",
          value: 7.95,
          date: "2022-4-10",
          time: "10:00",
        },
        {
          name: "上游DEF01挠度",
          level: "黄色预警",
          value: 7.95,
          date: "2022-4-10",
          time: "10:00",
        },
        {
          name: "上游DEF01挠度",
          level: "黄色预警",
          value: 7.95,
          date: "2022-4-10",
          time: "10:00",
        },
        {
          name: "上游DEF01挠度",
          level: "黄色预警",
          value: 7.95,
          date: "2022-4-10",
          time: "10:00",
        },
        {
          name: "上游DEF01挠度",
          level: "黄色预警",
          value: 7.95,
          date: "2022-4-10",
          time: "10:00",
        },
      ],
    };
  },
  filters: {
    formatName(value) {
      if (value.length < 5) {
        return value;
      } else {
        return value.slice(0, 4) + "...";
      }
    },
  },
  computed: {
    classOption() {
      return {
        step: 0.1, // 数值越大速度滚动越快
        limitMoveNum: this.alertList.length, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  mounted() {},
  methods: {
    onClickOutside() {
      this.showDropdown = false;
    },
    handleChange(item) {
      this.current = item.name;
      this.showDropdown = false;
    },
  },
};
</script>
